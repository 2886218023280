<template>
  <div>
    <p>{{ msg }}</p>
    <div id="createRooms">
      <h1>- Slidepuzzle Admin -</h1>
      <div class="websocket">
        <div class="testButton" @click="testWebsocket">Test</div>
        <div>{{ connectionText }}</div>
        <div v-if="errorMsg">{{ errorMsg }}</div>
        <div
          class="circle"
          :class="[
            errorMsg
              ? 'red'
              : !waiting && !socket_success
              ? 'orange'
              : socket_success
              ? 'green'
              : 'red',
          ]"
        ></div>
      </div>
      <div v-if="socket_success" class="websocket">
        Test the connection here:
        <input type="text" v-model="ws_text" />
        <div class="testButton" @click="sendToWs">SEND</div>
      </div>
      <div v-if="socket_success" class="output websocket">
        <div v-for="out in output" :key="out">{{ out }}</div>
      </div>
      <div>Password <input type="text" v-model="password" /></div>
      <div class="buttons">
        <div class="submit pointer" @click="erstellen">ERSTELLEN</div>
      </div>
      <div class="PlayerButtonHolder">
        <div
          class="addPlayer submit pointer"
          :class="length == 9 ? 'selected' : ''"
          @click="selectLength(9)"
        >
          3 x 3
        </div>
        <div
          class="addPlayer submit pointer"
          :class="length == 16 ? 'selected' : ''"
          @click="selectLength(16)"
        >
          4 x 4
        </div>
        <div
          class="addPlayer submit pointer"
          :class="length == 25 ? 'selected' : ''"
          @click="selectLength(25)"
        >
          5 x 5
        </div>
      </div>
      <div class="Modes">
        Mode:
        <div class="PlayerButtonHolder">
          <div
            v-for="m in modesAvailable"
            :key="m"
            class="addPlayer submit pointer cap"
            :class="mode == m ? 'selected' : ''"
            @click="selectMode(m)"
          >
            {{ m }}
          </div>
        </div>
      </div>
      <div class="PlayerButtonHolder">
        Difficulty:
        <div
          class="addPlayer submit pointer"
          @click="selectDifficulty(0)"
          :class="difficulty == 'easy' ? 'selected' : ''"
        >
          Easy (2 free tiles)
        </div>
        <div
          class="addPlayer submit pointer"
          @click="selectDifficulty(1)"
          :class="difficulty == 'hard' ? 'selected' : ''"
        >
          Hard (1 free tile)
        </div>
      </div>
      <div class="countdown">
        Countdown time (minutes):
        <input type="number" v-model="timer_amount" />
      </div>
      <div class="tries">
        Tries:
        <input type="number" v-model="tries_amount" />
      </div>
      <div class="PlayerButtonHolder">
        Tutorial:
        <div
          class="addPlayer submit pointer"
          @click="tutorialSelect(true)"
          :class="tutorial ? 'selected' : ''"
        >
          YES
        </div>
        <div
          class="addPlayer submit pointer"
          @click="tutorialSelect(false)"
          :class="!tutorial ? 'selected' : ''"
        >
          NO
        </div>
      </div>
      <p>click on the link to copy it!</p>
      <div class="codes" v-for="(p, index) in players" :key="index">
        <div>{{ index }}:</div>
        <div class="output pointer" @click="kopieren(index)">
          {{ textFilter(p) }}
        </div>
      </div>
    </div>
    <div id="version">v1.0</div>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";

export default {
  name: "Admin",
  components: {},
  data() {
    return {
      modesAvailable: ["countdown", "tries", "freeplay", "split"],
      password: "",
      pressed: false,
      msg: "",
      url: window.location.origin,
      players: {},
      length: 9,
      timer_amount: 5,
      mode: "freeplay",
      tries_amount: 100,
      tutorial: false,
      difficultyAvailable: ["easy", "hard"],
      difficulty: "easy",
      socket: {},
      socket_success: false,
      waiting: false,
      connectionText: "connection not tested",
      errorMsg: "",
      ws_text: "",
      output: [],
    };
  },
  methods: {
    selectDifficulty(selected) {
      this.difficulty = this.difficultyAvailable[selected];
    },
    tutorialSelect(selected) {
      this.tutorial = selected;
    },
    selectMode(m) {
      this.mode = m;
    },
    selectLength(l) {
      console.log(l);
      this.length = l;
    },
    timeFilter(time) {
      return time;
    },
    textFilter(text) {
      return "... " + text.substring(text.length - 20) + " ...";
    },
    kopieren(i) {
      if (this.tutorial) {
        copy(this.url + "/#/Tutorial?token=" + this.players[i]);
      } else {
        copy(this.url + "/#/?token=" + this.players[i]);
      }
    },

    erstellen() {
      this.players = {};

      let obj = {
        password: this.password,
        length: this.length,
        mode: this.mode,
        difficulty: this.difficulty,
        tries_amount: this.tries_amount,
        timer_amount: this.timer_amount,
      };
      console.log(obj);
      this.$http.post("/createToken", obj).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.msg = res.data.msg;
          if (res.data.type == "success") {
            this.players = res.data.tokens;
          }
        } else {
          this.msg =
            "connection to the server couldn't be established, check your internet connection ...";
        }
      });
      console.log("yip");
    },
    sendToWs() {
      if (this.ws_text.length == 0) return;
      if (this.socket_success) {
        this.socket.send(
          JSON.stringify({
            type: "message",
            data: this.ws_text,
          })
        );
      }
    },
    testWebsocket() {
      if (this.waiting) return;
      this.connectionText = "connecting to server...";
      this.waiting = true;
      this.socket_success = false;
      setTimeout(() => {
        if (!this.socket_success) {
          this.connectionText = "Server took to long to respond (>5s)";
          this.errorMsg = "Failed to connect";
          this.waiting = false;
        }
      }, 5000);

      setTimeout(() => {
        try {
          // this.socket = new WebSocket("ws://localhost:3000");
          this.socket = new WebSocket("wss://puzzle.meixneralex.at");

          // Connection opened
          this.socket.onopen = () => {
            this.socket.send(
              JSON.stringify({
                type: "GM",
              })
            );
          };

          this.socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const type = message.type || "";
            if (!type) return;
            if (type === "GM_success") {
              this.connectionText = "connecting successful";
              this.errorMsg = "";
              this.socket_success = true;
              this.waiting = false;
            } else if (type === "message") {
              this.output.push(message.data);
              this.ws_text = "";
            }
          };
        } catch (e) {
          this.connectionText = "Error";
          this.errorMsg = e;
          this.waiting = false;
        }
      }, 600);
    },
  },
  created() {},
};
</script>

<style scoped>
* {
  padding-left: 10px;
}

.cap {
  text-transform: capitalize;
}

.selected {
  background: red;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.submit {
  border: 2px solid #588fd3;
  width: 100px;
  text-align: center;
  line-height: 40px;
  padding-right: 10px;
}

.submit:hover {
  background: black;
  color: white;
}

.pointer:hover {
  cursor: pointer;
}

.output:hover {
  background: red;
}

.codes {
  display: flex;
}

.ButtonMargin {
  margin-left: 10px;
}

.BorderBottom {
  border-bottom: 4px solid white;
}

div#createRooms > div {
  padding: 10px;
}

button {
  padding: 10px;
  margin: 10px;
}

.orange,
.blue {
  color: white;
  font-size: 1.5em;
  word-break: break-all;
}

#seller,
#buyer {
  margin: 10px;
  cursor: pointer;
  max-width: 1000px;
}

.orange {
  background-color: rgb(253, 103, 33);
}

.blue {
  background-color: #1860b9;
}

#version {
  position: fixed;
  right: 0px;
  bottom: 0px;
}

.PlayerButtonHolder {
  max-width: 570px;
  display: flex;
  justify-content: space-around;
}

.red {
  background: red;
}

.green {
  background: lime;
}

.circle {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 0px;
  flex-shrink: 0;
}

.websocket {
  display: flex;
  align-items: center;
  width: 500px;
  margin: 0 auto;
  justify-content: space-around;
}

.testButton {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
}

.testButton:hover {
  background: black;
  color: white;
}
</style>
