<template>
  <div id="Puzzle">
    <div class="puzzle" :style="getWidth()">
      <template v-if="playerType == 'player'">
        <template v-for="(piece, index) in puzzle" :key="index">
          <div
            class="piece noselect player"
            :class="{
              zero: piece == 0,
              selected: index == selected,
              stripes: piece != 0 && !tutorial,
            }"
            @mousedown="mouseDown"
            @mouseup="mouseUp"
            @mousemove="mouseMove"
            @mouseenter="mouseEnter(index)"
            @mouseleave="mouseLeave"
          >
            <div v-if="tutorial">{{ piece }}</div>
          </div>
        </template>
      </template>
      <template v-if="playerType == 'watcher'">
        <div v-if="mode == 'split'" class="splitHolder">
          <div
            v-for="i in 4"
            :key="i"
            class="split"
            :class="[i - 1 == split ? '' : 'stripes']"
          ></div>
        </div>
        <template v-for="(piece, index) in puzzle" :key="index">
          <div class="piece noselect" :class="{ zero: piece == 0 }">
            {{ piece }}
          </div>
        </template>
      </template>
    </div>
    <div v-if="showTries">Moves: {{ tries }}</div>
    <div
      class="buttonClass orangeHover"
      v-if="playerType == 'watcher' || tutorial"
      @click="ende"
    >
      FINISH
    </div>
    <!-- {{ playerType }} -->
  </div>
</template>

<script>
export default {
  name: "Puzzle",
  components: {},
  props: {
    playerType: {
      type: String,
      default: () => "",
    },
    puzzleServer: {
      type: Array,
      default: () => [],
    },
    enableMove: {
      type: Boolean,
      default: () => false,
    },
    mode: {
      type: String,
      default: () => "freeplay",
    },
    split: {
      type: Number,
      default: () => -1,
    },
    tries_amount: {
      type: Number,
      default: () => 30,
    },
    tutorial: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    puzzleServer(newV) {
      this.puzzle = newV;
    },
    mode(newV) {
      switch (newV) {
        case "freeplay":
          break;
        case "countdown":
          break;
        case "tries":
          this.showTries = true;
          break;
      }
    },
  },
  data() {
    return {
      puzzle: this.puzzleServer,
      selected: -1,
      tries: 0,
      mouseHoverCurrent: -1,
      mouseHoverPrev: -1,
      mousePressed: false,
      showTries: false,
    };
  },
  methods: {
    getWidth() {
      // console.log(this.puzzle.length);
      if (this.puzzle.length == 9) {
        //3x3
        return "width:calc(126px * 3)";
      } else if (this.puzzle.length == 16) {
        //4x4
        return "width:calc(126px * 4)";
      }
      //5x5
      return "width:calc(126px * 5)";
    },
    mouseDown() {
      this.mousePressed = true;
    },
    mouseUp() {
      this.mousePressed = false;
    },
    mouseMove() {
      if (!this.mousePressed) return;
    },
    mouseEnter(index) {
      this.mouseHoverCurrent = index;
      if (this.mousePressed && this.enableMove) {
        this.switch2(this.mouseHoverCurrent, this.mouseHoverPrev);
      }
    },
    mouseLeave() {
      this.mouseHoverPrev = this.mouseHoverCurrent;
    },
    switch2(first, second) {
      //switches first and second element
      //check if they can switch
      console.log("trying to switch: " + first + " & " + second);
      if (!this.canSwitch2(first, second)) {
        return false;
      }
      const helper = this.puzzle[first];
      this.puzzle[first] = this.puzzle[second];
      this.puzzle[second] = helper;
      second = -1;
      this.tries++;
      this.$emit("moved", this.puzzle);
    },
    canSwitch2(first, second) {
      //compare selected and index to see if switchable
      var moeglich = [];
      // console.log(
      //   "first: " + this.puzzle[first] + " second: " + this.puzzle[second]
      // );
      if (this.puzzle[second] == 0) return false;
      if (this.puzzle.length == 9) {
        //3x3
        moeglich = [-1, 1, -3, 3];
        for (const m of moeglich) {
          if (second + m > 8) continue; //out of range
          if (first == 2 && second + m == 3) continue;
          if (first == 3 && second + m == 2) continue;
          if (first == 5 && second + m == 6) continue;
          if (first == 6 && second + m == 5) continue;
          if (this.puzzle[first] == 0) {
            if (second + m == first) return true;
          }
        }
      } else if (this.puzzle.length == 16) {
        //4x4
        moeglich = [-1, 1, -4, 4];
        for (const m of moeglich) {
          if (second + m > 15) continue; //out of range
          if (first == 3 && second + m == 4) continue;
          if (first == 4 && second + m == 3) continue;
          if (first == 6 && second + m == 7) continue;
          if (first == 7 && second + m == 6) continue;
          if (first == 11 && second + m == 12) continue;
          if (first == 12 && second + m == 11) continue;
          if (this.puzzle[first] == 0) {
            if (second + m == first) return true;
          }
        }
      } else if (this.puzzle.length == 25) {
        //5x5
        moeglich = [-1, 1, -5, 5];
        for (const m of moeglich) {
          if (second + m > 24) continue; //out of range
          if (first == 4 && second + m == 5) continue;
          if (first == 5 && second + m == 4) continue;
          if (first == 9 && second + m == 10) continue;
          if (first == 10 && second + m == 9) continue;
          if (first == 14 && second + m == 15) continue;
          if (first == 15 && second + m == 14) continue;
          if (first == 19 && second + m == 20) continue;
          if (first == 20 && second + m == 19) continue;
          if (this.puzzle[first] == 0) {
            if (second + m == first) return true;
          }
        }
      }
      return false;
    },
    ende() {
      if (!this.finish()) {
        this.$emit("loose", true);
        return;
      }
      switch (this.mode) {
        case "freeplay":
          this.$emit("win", true);
          break;
        case "countdown":
          this.$emit("win", true);
          break;
        case "tries":
          if (this.tries <= this.tries_amount) {
            this.$emit("win", true);
          }
          break;
      }
    },
    finish() {
      var freifelder = 3;
      if (this.puzzle.length == 25) freifelder = 4;
      for (var i = 0; i < this.puzzle.length - freifelder; i++) {
        // console.log(this.puzzle[i] + "<" + this.puzzle[i + 1]);
        if (this.puzzle[i] > this.puzzle[i + 1]) {
          console.log("FALSCH");
          return false;
        }
      }
      console.log("RICHTIG");
      return true;
    },
  },
  created() {
    console.log(this.tutorial);
  },
};
</script>

<style scoped>
.dark {
  background: rgb(255 0 0 / 50%);
}

.split {
  width: 252px;
  aspect-ratio: 1;
}

.splitHolder {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  flex-wrap: wrap;
}

.stripes {
  background: repeating-linear-gradient(
    315deg,
    var(--orange),
    var(--orange) 10px,
    #ffffff 10px,
    #ffffff 20px
  );
}

.piece:hover {
  background: var(--orange);
}

.zero:hover {
  color: var(--orange);
}

.selected.zero {
  color: var(--orange);
}

.selected {
  background: var(--orange);
}

.zero {
  border: 2px solid white !important;
  color: white;
}
.puzzle {
  display: flex;
  margin: 0 auto;
  width: calc(126px * 4);
  flex-direction: row;
  flex-wrap: wrap;
  aspect-ratio: 1;
  position: relative;
}

#Puzzle {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.piece {
  width: 120px;
  height: 120px;
  border: 2px solid black;
  border-radius: 5px;
  margin: 1px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
}
</style>
