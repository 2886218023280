import { createWebHashHistory, createRouter } from "vue-router";
import Main from '@/components/Main'
import Error from '@/components/Error'
import Admin from '@/components/Admin'
import Tutorial from '@/components/Tutorial'


const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    props: route => ({ query: route.query.token })
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: Tutorial,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/Error:msg',
    name: 'Error',
    component: Error,
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router
