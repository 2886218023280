<template>
  <div id="container">
    {{ msg }}
    <Transition>
      <div class="start" v-if="startOverlay">START</div>
    </Transition>
    <div v-if="!success || !playerTypeSet">Connecting to Server...</div>
    <div v-if="!success">Connecting to Server...</div>
    <div v-if="success && playerTypeSet && waiting">
      Waiting for other player to connect ...
    </div>
    <div v-if="success && playerTypeSet && !waiting" class="middle">
      <div v-if="winner" id="Success">YOU WON!</div>
      <div v-if="loose" id="Loose">TIME IS UP!</div>
      <Puzzle
        :playerType="playerType"
        :puzzleServer="puzzle_use"
        :winner="winner"
        :enableMove="enableMove"
        :mode="mode"
        :tries_amount="tries_amount"
        :split="split"
        @moved="updatePuzzle"
        @win="win"
        @loose="wrong"
      />
      <div v-if="playerType == 'watcher' && mode == 'countdown'">
        <Countdown
          :minutes="timer_amount"
          @finish="coutdownFinish"
          @start="startGame"
        />
      </div>
      <template
        v-if="!started && playerType == 'watcher' && mode == 'freeplay'"
      >
        <div class="buttonClass orangeHover" @click="startGame">START</div>
      </template>
      <template v-if="!started && playerType == 'watcher' && mode == 'tries'">
        <div class="buttonClass orangeHover" @click="startGame">START</div>
      </template>
    </div>
  </div>
</template>

<script>
// import { io } from "socket.io-client";
import Puzzle from "./Puzzle.vue";
import Countdown from "./Countdown.vue";

const puzzles = {
  easy: [
    [3, 0, 2, 6, 5, 7, 1, 4, 0],
    [3, 13, 0, 2, 6, 10, 12, 5, 7, 14, 1, 4, 0, 8, 9, 11],
    [
      3, 13, 0, 2, 6, 10, 12, 5, 7, 14, 1, 4, 0, 8, 9, 11, 15, 16, 17, 18, 19,
      20, 21, 22, 0,
    ],
  ],
  hard: [
    [3, 8, 2, 6, 5, 1, 7, 4, 0],
    [3, 13, 0, 2, 6, 10, 15, 5, 7, 14, 1, 4, 12, 8, 9, 11],
    [
      3, 13, 0, 2, 6, 10, 12, 5, 7, 14, 1, 4, 23, 8, 9, 11, 15, 16, 17, 18, 19,
      20, 21, 22, 0,
    ],
  ],
};

export default {
  name: "Main",
  components: {
    Puzzle,
    Countdown,
  },
  data() {
    return {
      msg: "",
      msgTimer: null,
      token: this.$route.query.token,
      playerTypeSet: false,
      playerType: "",
      roomCode: "abc",
      success: false,
      socket: {},
      winner: false,
      loose: false,
      puzzle_use: [],
      puzzle_auswahl: 0,
      mode: "freeplay",
      tries_amount: 0,
      timer_amount: 0,
      enableMove: false,
      enableTimer: false,
      startOverlay: false,
      started: false,
      split: -1,
      difficulty: "easy",
      waiting: true,
    };
  },
  methods: {
    isSovable(puzzleArray) {
      const inversions = this.countInversions(puzzleArray);
      var solvable = false;
      if (puzzleArray.length % 2 === 0) {
        //is even
        if (inversions % 2 === 0) {
          //even
          if (this.countRowFromBottom(puzzleArray) % 2 != 0) {
            solvable = true;
          }
        } else {
          //odd
          if (this.countRowFromBottom(puzzleArray) % 2 === 0) {
            solvable = true;
          }
        }
      } else {
        if (inversions % 2 === 0) {
          solvable = true;
        }
      }

      if (solvable) {
        console.log("Puzzle: " + JSON.stringify(puzzleArray) + " is solvable!");
        return true;
      } else {
        console.log(
          "Puzzle: " + JSON.stringify(puzzleArray) + " is NOT solvable!"
        );
        return false;
      }
    },
    countRowFromBottom(array) {
      //where is 0 located
      var row = -1;
      var zero = -1;
      var sideLength = Math.sqrt(array.length);
      for (var i = 0; i < array.length; i++) {
        if (array[i] === 0) zero = i;
      }

      row = Math.floor(zero / sideLength);
      return sideLength - row;
    },
    countInversions(array) {
      var inv = 0;
      for (var i = 0; i < array.length - 1; i++) {
        if (array[i] > array[i + 1]) inv++;
      }
      return inv;
    },
    updatePuzzle(newPuzzle) {
      console.log(puzzles[this.difficulty][this.puzzle_auswahl]);
      console.log(this.puzzle_use);
      this.socket.send(
        JSON.stringify({
          type: "updatePuzzle",
          data: newPuzzle,
          roomCode: this.roomCode,
        })
      );
    },
    win() {
      this.socket.send(
        JSON.stringify({
          type: "winner",
          data: true,
          roomCode: this.roomCode,
        })
      );
    },
    wrong() {
      // this.socket.send("loose", true);
    },
    startGame() {
      this.socket.send(
        JSON.stringify({
          type: "start",
          data: true,
          roomCode: this.roomCode,
        })
      );
    },
    showStartOverlay() {
      this.startOverlay = true;
      this.started = true;
      setTimeout(() => {
        this.startOverlay = false;
      }, 800);
    },
    coutdownFinish() {
      this.socket.send(
        JSON.stringify({
          type: "countDownFinish",
          data: true,
          roomCode: this.roomCode,
        })
      );
    },
    connect() {
      // this.socket = io("localhost:3000", {
      //   auth: {
      //     token: this.roomCode,
      //   },
      // });
      // this.socket = io("https://puzzle.meixneralex.at", {
      //   auth: {
      //     token: this.roomCode,
      //   },
      // });

      try {
        // this.socket = new WebSocket("ws://localhost:3001");
        this.socket = new WebSocket("wss://puzzle.meixneralex.at");

        // Connection opened
        this.socket.onopen = () => {
          this.socket.send(
            JSON.stringify({
              type: "init",
              roomCode: this.roomCode,
            })
          );
        };

        this.socket.onmessage = (event) => {
          console.log(event);
          const message = JSON.parse(event.data);

          const type = message.type || "";
          const data = message.data || {};
          if (!type) return;
          switch (type) {
            case "error":
              this.setMessage(data);
              break;
            case "success":
              this.success = true;
              if (data.spielerAnzahl > 0) {
                this.waiting = false;
              }
              console.log("success");
              break;
            case "disconnect":
              break;
            case "reset":
              this.reset();
              break;
            case "win":
              this.winner = true;
              break;
            case "updatePuzzle":
              this.puzzle_use = data;
              break;
            case "start":
              this.enableMove = true;
              this.showStartOverlay();
              break;
            case "countDownFinish":
              this.loose = true;
              //No success
              break;
            case "otherPlayerJoined":
              this.reset();
              break;
            default:
              console.warn(`Type: ${type} unknown`);
              break;
          }
        };
      } catch (e) {
        console.log(e);
      }
    },
    reset() {
      this.started = false;
      this.enableMove = false;
      this.waiting = false;
      console.log("reset");
      this.puzzle_use = [];
      this.puzzle_use = puzzles[this.difficulty][this.puzzle_auswahl];
    },
    disconnect() {
      this.socket.send(
        JSON.stringify({
          type: "disconnect",
          roomCode: this.roomCode,
        })
      );
    },
    checkToken(connect) {
      this.$http
        .post("/checkToken", {
          token: this.token,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.setMessage(res.data.msg);
            if (res.data.type == "success") {
              if (res.data.playerType != "") {
                console.log("YES");
                this.playerType = res.data.playerType;
                this.playerTypeSet = true;
                console.log("Länge: " + res.data.puzzleLength);

                const difficulty = res.data.difficulty || "easy";

                if (res.data.puzzleLength == 16) {
                  //4x4
                  this.minutes = 3;
                  this.puzzle_use = [...puzzles[difficulty][1]];
                  this.puzzle_auswahl = 1;
                } else if (res.data.puzzleLength == 25) {
                  //5x5
                  this.minutes = 5;
                  this.puzzle_use = [...puzzles[difficulty][2]];
                  this.puzzle_auswahl = 2;
                } else {
                  //3x3
                  this.minutes = 1;
                  this.puzzle_use = [...puzzles[difficulty][0]];
                  this.puzzle_auswahl = 0;
                }

                // if (res.data.countdown) {
                //   this.enableTimer = true;
                // }

                if (res.data.mode) {
                  this.mode = res.data.mode;
                  this.tries_amount = res.data.tries_amount;
                  this.timer_amount = res.data.timer_amount;
                }

                if (res.data.roomCode) {
                  this.roomCode = res.data.roomCode;
                }

                this.split = res.data.split;
                this.difficulty = difficulty;

                if (connect === true) this.connect();
                return true;
              } else {
                return false;
              }
            } else {
              this.$router.push({
                name: "Error",
                params: { msg: res.data.msg },
              });
            }
          } else {
            this.setMessage(
              "cannot connect to server, please check your connection or reload the page"
            );
            // this.setMessage('error bei der Verbindung zum Server, versuchen sie es erneut, checken sie die Internetverbindung...')
            return false;
          }
        });
    },
    setMessage(msg) {
      this.msg = msg;
      if (this.msgTimer) {
        clearTimeout(this.msgTimer);
      }
      this.msgTimer = setTimeout(() => {
        this.msg = "";
      }, 3000);
    },
  },
  created() {
    this.checkToken(true);
    // this.isSovable(puzzles.hard[0]);
    // this.isSovable(puzzles.hard[1]);
    // this.isSovable(puzzles.hard[2]);
  },

  beforeUnmount() {
    console.log("YEs");
    this.disconnect();
    if (this.socket) this.disconnect();
  },
};
</script>

<style scoped>
.middle {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.middle > div {
  margin: 0 50px;
}

.start {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 300px;
  pointer-events: none;
  -webkit-text-stroke: 3px var(--orange);
  color: #eaeaea;
}

#container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#Success,
#Loose {
  position: absolute;
  background: red;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  font-size: 7em;
  color: white;
  z-index: 1;
}
</style>
