<template>
  <div id="container">
    <h1>ERROR</h1>
    <div>{{ msg }}</div>
    <div>Please talk to your GameMaster for help</div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      msg: "",
    };
  },
  created() {
    this.msg = this.$route.params.msg;
  },
};
</script>

<style scoped>
#container {
  text-align: center;
}
</style>
