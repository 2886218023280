<template>
  <div id="Countdown">{{ time }}</div>
  <div class="start" v-if="!started" @click="start()">START</div>
</template>

<script>
export default {
  name: "Countdown",
  components: {},
  emits: ["finish", "timeLeft", "start"],
  props: {
    minutes: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      time: this.minutes + ":00",
      timeBegan: null,
      started: null,
      running: false,
      stoppedDuration: 0,
      timeToReach: {},
    };
  },

  methods: {
    init() {
      const MINUTE = 60 * 1000;
      //add the minutes to the time
      this.timeToReach = new Date(
        Date.parse(new Date()) + this.minutes * MINUTE
      );

      var currentTime = new Date();

      var timeLeft = new Date(this.timeToReach - currentTime),
        min = timeLeft.getUTCMinutes(),
        sec = timeLeft.getUTCSeconds();

      this.time = this.zeroPrefix(min, 2) + ":" + this.zeroPrefix(sec, 2);
    },
    timeLeft() {
      this.stop();
      this.$emit("timeLeft", this.time);
    },
    finish() {
      this.stop();
      this.time = "00:00";
      this.$emit("finish", true);
    },
    start() {
      this.$emit("start", true);
      this.reset();
      this.init();
      this.timeBegan = new Date();

      this.started = setInterval(this.clockRunning, 100);
      this.running = true;
    },
    stop() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
    },
    reset() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = this.minutes + ":00";
    },
    clockRunning() {
      var currentTime = new Date();

      var timeLeft = new Date(this.timeToReach - currentTime),
        min = timeLeft.getUTCMinutes(),
        sec = timeLeft.getUTCSeconds();

      this.time = this.zeroPrefix(min, 2) + ":" + this.zeroPrefix(sec, 2);

      if (timeLeft <= 0) {
        this.finish();
        return;
      }
    },
    zeroPrefix(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
  },
  created() {
    // this.init();
  },
};
</script>

<style scoped>
.start:hover {
  cursor: pointer;
  background: black;
  color: white;
}

.start {
  margin: 10px auto;
  border: 2px solid black;
  width: 100px;
  border-radius: 10px;
}

#Countdown {
  font-size: 70px;
  margin-top: 80px;
}
</style>
