import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

const $http = axios.create({
  baseURL: location.protocol+"//"+location.hostname+"/api", //prod on PLESK
  // baseURL: location.protocol+"//"+location.hostname+":3000/api", //dev
})

const app = createApp(App)
app.use(router)
app.use(VueAxios, $http)

app.mount('#app')

