<template>
  <div id="Tutorial">
    {{ msg }}
    <Transition>
      <div class="start" v-if="correctOverlay">Correct</div>
    </Transition>
    <Transition>
      <div class="start" v-if="wrongOverlay">Wrong</div>
    </Transition>
    <h1>Welcome to the tutorial. Step {{ step + 1 }}/3</h1>
    <template v-if="step == 0">
      <div class="text">
        <p>
          Your goal is to bring the puzzle pieces into the order shown below:
        </p>
        <div class="puzzle">
          <template
            v-for="(piece, index) in [1, 2, 3, 4, 5, 6, 7, 0, 0]"
            :key="index"
          >
            <div
              class="piece noselect player"
              :class="{
                zero: piece == 0,
              }"
            >
              {{ piece }}
            </div>
          </template>
        </div>
        <p>
          You achieve this by clicking and dragging the pieces one after the
          other.<br />
          Only pieces next to an empty slot can be moved.<br />
          When you have solved the puzzle click "FINISH".<br />
        </p>
      </div>
      <div id="puzzleNbuttons2">
        <Puzzle
          :playerType="'player'"
          :puzzleServer="puzzle_use"
          :enableMove="'true'"
          :mode="'freeplay'"
          :tutorial="tutorial"
          @moved="updatePuzzle"
          @win="win"
          @loose="loose"
        />
        <div
          v-if="showNext"
          class="buttonClass orangeHover nextButton"
          @click="nextStep"
        >
          <div>>></div>
          <div>Next Step</div>
          <div>>></div>
        </div>
      </div>
    </template>
    <template v-if="step == 1">
      <div class="text">
        <p>
          You have successfully solved the first puzzle.<br />
          In the real game it will not be so easy!<br />
          You will play as a team of 2.<br />
          One will be able to move the pieces, but cannot see the numbers, the
          other will see the number but cannot move! <br />
          It is your job to coordiante these tasks and solve the puzzle
          together.<br />
          Try it out below, but remember: Only the right side can move the
          pieces
        </p>
      </div>
      <div id="puzzleNbuttons2">
        <Puzzle
          :playerType="'watcher'"
          :puzzleServer="puzzle_use"
          :enableMove="'true'"
          :mode="'freeplay'"
          :tutorial="tutorial"
          @moved="updatePuzzle"
          @win="win"
          @loose="loose"
        />
        <Puzzle
          :playerType="'player'"
          :puzzleServer="puzzle_use"
          :enableMove="'true'"
          :mode="'freeplay'"
          :tutorial="tutorial"
          @moved="updatePuzzle"
          @win="win"
          @loose="loose"
        />
        <div
          v-if="showNext"
          class="buttonClass orangeHover nextButton"
          @click="nextStep"
        >
          <div>>></div>
          <div>Next Step</div>
          <div>>></div>
        </div>
      </div>
    </template>
    <template v-if="step == 2">
      <div class="text">
        <p>
          Thats it. You are ready to play!<br />
          Click the button below to start the game:
        </p>
      </div>
      <div class="buttonClass orangeHover" @click="clickedLink">
        Start the game
      </div>
    </template>
  </div>
</template>

<script>
import Puzzle from "./Puzzle.vue";

export default {
  name: "Tutorial",
  components: {
    Puzzle,
  },
  data() {
    return {
      msg: "",
      msgTimer: null,
      success: false,
      puzzle_use: [1, 2, 3, 4, 0, 6, 5, 7, 0],
      tutorial: true,
      correctOverlay: false,
      wrongOverlay: false,
      step: 0,
      showNext: false,
      token: this.$route.query.token,
    };
  },
  methods: {
    clickedLink() {
      //router move to new link
      // console.log("/?token=" + this.token);
      this.$router.push("/?token=" + this.token);
    },
    setMessage(msg) {
      this.msg = msg;
      if (this.msgTimer) {
        clearTimeout(this.msgTimer);
      }
      this.msgTimer = setTimeout(() => {
        this.msg = "";
      }, 3000);
    },
    showOverlay(type) {
      if (type == "correct") {
        this.correctOverlay = true;
        setTimeout(() => {
          this.correctOverlay = false;
        }, 1400);
      } else {
        this.wrongOverlay = true;
        setTimeout(() => {
          this.wrongOverlay = false;
        }, 1400);
      }
    },
    win() {
      this.showOverlay("correct");
      this.showNext = true;
    },
    loose() {
      this.showOverlay("wrong");
    },
    nextStep() {
      this.step++;
      if (this.step == 1) {
        this.puzzle_use = [1, 2, 3, 4, 0, 6, 5, 7, 0];
        this.tutorial = false;
      }
      this.showNext = false;
    },
    previousStep() {
      this.step--;
    },
    updatePuzzle(puzzle) {
      console.log(puzzle);
    },
  },
  created() {},
};
</script>

<style scoped>
.puzzle {
  display: flex;
  margin: 0 auto;
  width: calc(56px * 3);
  flex-direction: row;
  flex-wrap: wrap;
  aspect-ratio: 1;
}

.piece {
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 5px;
  margin: 1px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 30px;
  cursor: pointer;
}

.zero {
  border: 2px solid white !important;
  color: white;
}

.nextButton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 5px;
  flex-direction: column;
}

#Tutorial {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -60px;
}

#puzzleNbuttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#puzzleNbuttons2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#puzzleNbuttons2 > div {
  margin: 0px 70px;
}

.text {
  width: 810px;
  font-size: 25px;
}

.start {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 300px;
  pointer-events: none;
  -webkit-text-stroke: 3px var(--orange);
  color: #eaeaea;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
